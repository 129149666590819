import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

const Comprobante = props => {
  const { folio } = props.match.params;

  const [created, setCreated] = useState(false);
  const [error, setError] = useState("generando comprobante");

  useEffect(() => {
    createComprobante();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createComprobante = () => {
    axios
      .get(`/api/comprobante/${folio}`)
      .then(res => {
        setCreated(true);
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error);
      });
  };

  return (
    <div className="comprobante">
      <div className="return">
        <Link to="/corredores">
          <img src="../img/icons/return.svg" alt="return" />
          Regresar / Añadir Participante
        </Link>
      </div>
      <div className="thankyou">
        <h1>FELICIDADES</h1>
        <h2>¡Registro exitoso!</h2>
        {created ? (
          <Link
            to={`/comprobantes/${folio}.pdf`}
            className="download"
            target="_blank"
          >
            <img src="../img/icons/download.svg" alt="pdf"></img>
            <p>descargar comprobante</p>
          </Link>
        ) : (
          <div className="spinner">
            <img src="../img/icons/spinner.gif" alt="spinner"></img>
            <p className={error ? "comprobante-error" : ""}>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comprobante;
