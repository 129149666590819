import React from "react";
import { Link } from "react-router-dom";

const Eror_404 = () => {
  return (
    <div className="error-404">
      <h1>404</h1>
      <h4>La página que buscas no existe.</h4>
      <Link to="/">
        <img src="/img/icons/return.svg" alt="regresar"></img>
        <span>Regresar</span>
      </Link>
    </div>
  );
};

export default Eror_404;
