import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Corredores from "./components/pages/Corredores";
import Registro from "./components/pages/Registro";
import Comprobante from "./components/pages/Comprobante";

import Error_404 from "./components/pages/Error_404";

import "./App.scss";

function App() {
  return (
    <div className="app">
      <Router>
        <Fragment>
          <Header></Header>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/corredores" component={Corredores} />
            <Route exact path="/registro" component={Registro} />
            <Route exact path="/comprobante/:folio" component={Comprobante} />
            <Route exact path="/:webname" component={Login} />
            <Route component={Error_404} />
          </Switch>
          <Footer></Footer>
        </Fragment>
      </Router>
    </div>
  );
}

export default App;
