import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

const Login = props => {
  const { webname } = props.match.params;

  const [empresa, setEmpresa] = useState({});
  const [colaborador, setColaborador] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    getEmpresa(webname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmpresa = webname => {
    axios({
      url: `/api/empresas/${webname}`
    })
      .then(res => {
        setEmpresa(res.data);
      })
      .catch(err => {
        props.history.push("/");
      });
  };

  const onColaboradorChange = e => {
    setColaborador(e.target.value);
  };

  const onClick = () => {
    if (colaborador !== "") {
      setError("");
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      axios
        .post(
          `/api/auth/`,
          {
            empresa_id: empresa.id,
            colaborador
          },
          config
        )
        .then(res => {
          const token = res.data.token;
          localStorage.setItem("token", token);
          axios.defaults.headers.common["x-auth-token"] = token;
          props.history.push("/corredores");
        })
        .catch(err => {
          setError(err.response.data.error);
          localStorage.removeItem("token");
          delete axios.defaults.headers.common["x-auth-token"];
        });
    }
  };

  return (
    <div className="login">
      <div className="return">
        <Link to="/">
          <img src="img/icons/return.svg" alt="return" />
          Cambiar de empresa
        </Link>
      </div>
      <div className="login-form">
        {empresa.webname ? (
          <img src={`img/logos/${empresa.webname}.jpg`} alt={empresa.webname} />
        ) : (
          ""
        )}
        <span className="login-error">{error ? error : ""}</span>
        <input
          type="number"
          placeholder="MI NÚMERO DE COLABORADOR"
          value={colaborador}
          onChange={onColaboradorChange}
        />
        <button onClick={onClick}>INGRESAR</button>
      </div>
    </div>
  );
};

export default Login;
