import React from "react";

const TopBar = () => {
  return (
    <div className="header">
      <img className="banner" src="/img/banner.png" alt="Kuo2020 Banner" />
      <img
        className="logo"
        src="/img/logos/delcorredor.png"
        alt="Delcorredor.com logo"
      />
    </div>
  );
};

export default TopBar;
