import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

const Registro = props => {
  const search = new URLSearchParams(props.location.search);
  const invitado = search.has("invitado");

  const [corredor, setCorredor] = useState({
    nombre: "",
    email: "",
    año: 2019,
    mes: 1,
    dia: 1,
    genero: "V",
    categoria_id: invitado ? 4 : 1,
    talla: "Mediana"
  });
  const [categorias, setCategorias] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["x-auth-token"] = token;
    } else {
      props.history.push("/");
    }
    getColaborador();
    getCategorias();
    getTallas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColaborador = () => {
    axios
      .get(`/api/colaborador`)
      .then(res => {
        if (!invitado) {
          setCorredor({
            ...corredor,
            nombre: res.data.nombre
          });
        }
      })
      .catch(err => {
        props.history.push("/");
      });
  };

  const getCategorias = () => {
    axios
      .get(`/api/categorias`)
      .then(res => {
        setCategorias(
          res.data.map(value => {
            if (invitado) {
              if (value.tipo === "2") {
                return (
                  <option value={value.id} key={`cat-${value.id}`}>
                    {value.nombre}
                  </option>
                );
              } else {
                return "";
              }
            } else {
              if (value.tipo === "1") {
                return (
                  <option value={value.id} key={`cat-${value.id}`}>
                    {value.nombre}
                  </option>
                );
              } else {
                return "";
              }
            }
          })
        );
      })
      .catch(err => {
        console.log(err);
        setError(
          "No se pudieron cargar las categorias, favor de intentar mas tarde."
        );
      });
  };

  const getTallas = () => {
    axios
      .get(`/api/tallas`)
      .then(res => {
        setTallas(
          res.data.map(value => {
            return (
              <option value={value} key={value}>
                {value}
              </option>
            );
          })
        );
      })
      .catch(err => {
        console.log(err);
        setError(
          "No se pudieron cargar las tallas, favor de intentar mas tarde."
        );
      });
  };

  let años = [];
  for (let i = 2019; i >= 1960; i--) {
    años.push(
      <option value={i} key={`años-${i}`}>
        {i}
      </option>
    );
  }

  let meses = [];
  for (let i = 1; i <= 12; i++) {
    meses.push(
      <option value={i} key={`meses-${i}`}>
        {i}
      </option>
    );
  }

  let dias = [];
  for (let i = 1; i <= 31; i++) {
    dias.push(
      <option value={i} key={`dias-${i}`}>
        {i}
      </option>
    );
  }

  const onChange = e =>
    setCorredor({ ...corredor, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios.post(
        `/api/registrar${invitado ? "?invitado" : ""}`,
        corredor,
        config
      );
      props.history.push(`/comprobante/${res.data.folio}`);
    } catch (err) {
      if (err.response.status === 400) {
        setError(err.response.data.error);
      } else {
        setError("Error interno");
        console.log(err.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className="registro">
      <div className="logout">
        <Link to="/">
          Salir
          <img src="../img/icons/logout.svg" alt="logout" />
        </Link>
      </div>
      <h2 className="registro-title">Nuevo registro</h2>
      <form className="registro-form" onSubmit={onSubmit}>
        <label>Nombre + Apellidos</label>
        <input
          type="text"
          name="nombre"
          value={corredor.nombre}
          onChange={onChange}
          required
        />
        <label>Correo electronico</label>
        <input
          type="text"
          name="email"
          value={corredor.email}
          onChange={onChange}
          required
        />
        <div className="fecha_nacimiento">
          <label>Fecha de nacimiento</label>
          <select name="año" value={corredor.año} onChange={onChange} required>
            {años}
          </select>
          <select name="mes" value={corredor.mes} onChange={onChange} required>
            {meses}
          </select>
          <select name="dia" value={corredor.dia} onChange={onChange} required>
            {dias}
          </select>
        </div>
        <div className="genero">
          <label>Genero</label>
          <select
            name="genero"
            value={corredor.genero}
            onChange={onChange}
            required
          >
            <option value="V">Masculino</option>
            <option value="F">Femenino</option>
          </select>
        </div>
        <hr></hr>
        <label>Categoria</label>
        <select
          className="full-width"
          name="categoria_id"
          value={corredor.categoria_id}
          onChange={onChange}
          required
        >
          {categorias}
        </select>
        <label>Tallas</label>
        <select
          className="full-width"
          name="talla"
          value={corredor.talla}
          onChange={onChange}
          required
        >
          {tallas}
        </select>

        <div className="buttons">
          <span className="error">{error ? error : ""}</span>
          <Link to="/corredores" className="btn btn-cancel">
            CANCELAR
          </Link>
          <button className="btn btn-submit" disabled={loading ? true : false}>
            INSCRIBIR
          </button>
        </div>
      </form>
    </div>
  );
};

export default Registro;
