import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

const Corredores = props => {
  const [colaborador, setColaborador] = useState({});
  const [invitados, setInvitados] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["x-auth-token"] = token;
    } else {
      props.history.push("/");
    }
    getColaborador();
    getInvitados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColaborador = () => {
    axios
      .get(`/api/colaborador`)
      .then(res => {
        setColaborador(res.data);
      })
      .catch(err => {
        console.log(err.response);
      });
  };

  const getInvitados = () => {
    axios
      .get(`/api/invitados`)
      .then(res => {
        setInvitados(res.data);
      })
      .catch(err => {
        console.log(err.response);
      });
  };

  const jsx_invitados = invitados.map(value => {
    return (
      <Link
        key={`folio-${value.folio}`}
        to={`/comprobantes/${value.folio}.pdf`}
        target="_blank"
        className="corredor invitado folio"
      >
        {value.nombre}
        <i className="fa fa-download" aria-hidden="true"></i>
      </Link>
    );
  });

  let jsx_noinvitados = [];

  for (let i = 0; i < colaborador.max_invitados - invitados.length; i++) {
    jsx_noinvitados.push(
      <Link
        to="/registro?invitado"
        className="corredor invitado"
        key={`no-invitado-${i}`}
      >
        <i className="fa fa-user-plus" aria-hidden="true"></i> INSCRIBIR
      </Link>
    );
  }

  return (
    <div className="corredores">
      <div className="logout">
        <Link to="/">
          Salir
          <img src="img/icons/logout.svg" alt="logout" />
        </Link>
      </div>
      <div className="corredores-logo">
        {colaborador.webname ? (
          <img
            src={`img/logos/${colaborador.webname}.jpg`}
            alt={colaborador.webname}
          />
        ) : (
          <span></span>
        )}
      </div>
      <div className="corredores-lista">
        <label className="label label-c">COLABORADOR</label>
        {colaborador.folio ? (
          <Link
            target="_blank"
            to={`/comprobantes/${colaborador.folio}.pdf`}
            className="corredor colaborador folio"
          >
            {colaborador.nombre}
            <i className="fa fa-download" aria-hidden="true"></i>
          </Link>
        ) : (
          <Link to={{ pathname: "/registro" }} className="corredor colaborador">
            <i className="fa fa-user-plus" aria-hidden="true"></i> INSCRIBIR
          </Link>
        )}
        <br></br>
        <label className="label label-i">MIS INVITADOS</label>
        {jsx_invitados}
        {jsx_noinvitados}
      </div>
    </div>
  );
};

export default Corredores;
