import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

const Home = () => {
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    getEmpresas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmpresas = () => {
    axios({
      url: "/api/empresas"
    })
      .then(res => {
        setEmpresas(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="home">
      <h2>Selecciona tu empresa</h2>
      <div className="empresas">
        {empresas.map(value => {
          return (
            <Link to={`/${value.webname}`} key={value.id}>
              <div className="btn-empresa">
                <img
                  src={`/img/logos/${value.webname}.jpg`}
                  alt={value.webname}
                ></img>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Home;
