import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p className="title">CONTACTO</p>
      <p>
        <a href="https://wa.me/+525576759339?text=Hola">
          <i className="fa fa-whatsapp" aria-hidden="true"></i> 55 767 593 39
        </a>
      </p>
      <p>
        <a href="mailto:contacto@delcorredor.com?Subject=Ayuda%20Kuo2020">
          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
          contacto@delcorredor.com
        </a>
      </p>
    </div>
  );
};

export default Footer;
